import React, { memo, useMemo } from "react";
import type { ElementOf } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import { MAX_MATCHES } from "@/game-deadlock/constants/enums.mjs";
import type { Match } from "@/game-deadlock/models/match.mjs";
import type { MatchlistMeta } from "@/game-deadlock/models/matchlist.mjs";
import usePlayerHeroStatsAll from "@/game-deadlock/utils/use-player-hero-stats.mjs";
import type { HitStats } from "@/shared-fps/hit-stats-types.mjs";
import HitStatsShared from "@/shared-fps/HitStats.jsx";
import sanitizeNumbersByObject from "@/shared-fps/sanitize-numbers-by-object.mjs";
import { filterErrState } from "@/util/eval-state.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const EMPTY_HIT_STATS: HitStats = {
  avgDamagePerRound: 0,
  weaponHits: {
    headshots: 0,
    bodyshots: 0,
    legshots: 0,
    chestshots: 0,
    stomachshots: 0,
  },
};

export default memo(function MatchTileHits({
  matchId,
  steamId,
}: {
  matchId: string;
  steamId: string;
}) {
  const {
    deadlock: { matchlistMeta },
  } = useSnapshot(readState);
  const hitStats = useMemo(
    () =>
      getHitStatsByMatch(
        filterErrState(matchlistMeta[steamId]?.[matchId])?.player,
      ),
    [matchId, matchlistMeta, steamId],
  );
  const playerHeroStats = usePlayerHeroStatsAll(steamId);
  const comparisonStats = useMemo(() => {
    const p = playerHeroStats;
    const result = {
      matches: Math.min(p.games_played, MAX_MATCHES), // Just representational UI
      avgDamagePerRound: p.player_damage / p.games_played,
      weaponHits: {
        headshots: p.hero_bullets_hit_crit / p.games_played,
        bodyshots:
          (p.hero_bullets_hit - p.hero_bullets_hit_crit) / p.games_played,
        legshots: 0,
        chestshots: 0,
        stomachshots: 0,
      },
    };
    sanitizeNumbersByObject(result, true);
    return result;
  }, [playerHeroStats]);
  return (
    <HitStatsShared
      hasLegshots={false}
      matchStats={hitStats}
      comparisonStats={comparisonStats}
      division={undefined}
      hasDmgPerRound
      hideHeadshotData={false}
      table={null}
      splitTorso={false}
    />
  );
});

function getHitStatsByMatch(
  player?: MatchlistMeta["player"] | ElementOf<Match["players"]>,
): HitStats {
  if (!player) return EMPTY_HIT_STATS;
  const stats = player.stats.at(-1);
  const result = {
    avgDamagePerRound: stats?.player_damage,
    weaponHits: {
      headshots: stats?.hero_bullets_hit_crit,
      bodyshots: stats?.hero_bullets_hit - stats?.hero_bullets_hit_crit,
      legshots: 0,
      chestshots: 0,
      stomachshots: 0,
    },
  };
  sanitizeNumbersByObject(result, true);
  return result;
}
